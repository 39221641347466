<template>
  <section class="second-section main-section middle-section">
    <div class="middle-section-top">
      <p
        class="middle-section-text second-section-title is--active text-effect-text-wrap"
      >
        <span data-aos="text-effect">보리</span>
        <b data-aos="text-effect" data-aos-delay="200">의료비 지원액</b>
      </p>
      <p class="count__period text-effect-text-wrap">
        <span data-aos="text-effect" data-aos-delay="400"
          >2019년 부터 2025년 까지 기준</span
        >
      </p>
    </div>
    <div class="middle-section-count">
      <div
        data-aos="fade-up"
        data-aos-delay="600"
        ref="countElement"
        class="count-element"
      >
        <span class="count__number target" id="numberCount"></span>
        <span class="count__unit">억원</span>
      </div>
    </div>
  </section>
</template>

<script>
import { CountUp } from "countup.js/dist/countUp.js";
import {
  ref,
  defineComponent,
  onMounted,
  nextTick,
  onBeforeUnmount,
} from "vue";

export default defineComponent({
  name: "SecondSection",
  components: {},
  setup() {
    const options = {
      startVal: 0,
      duration: 1,
    };
    const countElement = ref(null);

    const onClassChange = (classAttrValue) => {
      const classList = classAttrValue.split(" ");
      if (classList.includes("aos-animate")) {
        const countUp = new CountUp("numberCount", 15, options);
        countUp.start();
        observer.disconnect();
      }
    };

    const observer = new MutationObserver((mutations) => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName);
        nextTick(() => {
          onClassChange(newValue, m.oldValue);
        });
      }
    });

    onMounted(() => {
      observer.observe(countElement.value, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ["class"],
      });
    });

    onBeforeUnmount(() => {
      observer.disconnect();
    });
    return {
      countElement,
    };
  },
});
</script>

<style scoped lang="scss">
.second-section {
  background-color: $point;
  color: $white;
  .second-section-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    b {
      font-weight: 700;
    }
  }
  .count__number {
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
  }
  .count__unit {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    margin-left: 5px;
  }
  .count__period {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 20px;
  }
}
@include mobileMiddle();
</style>
