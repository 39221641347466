<template>
  <section class="first-section main-section">
    <div class="first-section-content">
      <div class="section-text">
        <h1 class="section__title">
          보이는<br />
          희망의 시작<br />
          <strong class="section__title--impact">보리</strong>
        </h1>
        <p class="section__description">
          <span class="section__description-text"
            ><b>대한민국 30%</b>를 위한 의료비지원 플랫폼</span
          >
        </p>
        <div class="section-button-area">
          <a
            href="https://play.google.com/store/apps/details?id=app.bori"
            target="_blank"
            class="section__button play-store"
            >Google Play</a
          >
          <a
            href="https://apps.apple.com/kr/app/%EB%B3%B4%EB%A6%AC/id1615137312"
            class="section__button app-store"
            >App Store</a
          >
        </div>
      </div>
      <div class="first-section-img-left first-section-img-bg">
        <img src="@/assets/images/main/first-img-left.png" alt="" />
      </div>
      <div class="first-section-img-area">
        <img
          src="@/assets/images/main/first-img-0310.png"
          alt="보리의 화면"
          class="first-section-img"
        />
      </div>
      <div class="first-section-img-right first-section-img-bg">
        <img src="@/assets/images/main/first-img-right.png" alt="" />
      </div>
    </div>
    <div class="section-bottom-img left"></div>
    <div class="section-bottom-img right"></div>
  </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FirstSection",
  setup() {},
});
</script>

<style lang="scss" scoped>
.first-section {
  display: flex;
  padding-left: 15.31vw;
  align-items: center;
  @include respond-to(1440) {
    padding-left: 10.41vw;
  }

  .section__button {
    padding: 12px 40px 12px 72px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: 40px 50%;
    background-size: 24px;
    box-sizing: border-box;
    border-radius: 6px;
    @include setText(14, 400, 18);
    @include fadeInPrepare(0.5);

    &.app-store {
      margin-left: 16px;
      background-color: $black;
      color: $white;
      background-image: url("../../../assets/images/icons/apple-store.png");
    }
    &.play-store {
      background-color: $vary-light-gray;
      color: $black;
      background-image: url("../../../assets/images/icons/play-store.png");
    }
  }
}
.section-text {
  .section {
    &__title {
      color: $light-black;
      @include setText(64, 400, 80);
      position: relative;
      @include fadeInUpPrepare();
      @include respond-to(1220) {
        font-size: 3.33vw;
        line-height: 4.16vw;
      }
      &:before {
        content: "";
        width: 0;
        height: 24px;
        position: absolute;
        top: -36px;
        left: -16px;
        transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
        background: url("../../../assets/images/common/line.png") no-repeat 50%
          50% / contain;
      }
      &--impact {
        font-weight: 700;
      }
    }
    &__description {
      color: $black;
      margin: 16px 0 48px;
      @include fadeInUpPrepare();

      &-text {
        position: relative;
        &:after {
          content: "";
          height: 5px;
          position: absolute;
          background: $light-point;
          z-index: -1;
          display: inline-block;
          left: 0;
          bottom: 3px;
          width: 100%;
        }
      }
    }
  }
}
.section-bottom-img {
  height: 0;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  background-image: url("../../../assets/images/main/first-bottom-bg.png");
  width: 104px;
  transform-origin: left center;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
  &.left {
    left: 7.7vw;
    @include respond-to(1440) {
      left: 2.7vw;
    }
  }
  &.right {
    right: 7.7vw;
    @include respond-to(1440) {
      right: 2.7vw;
    }
  }
}
.first-section-img-area {
  position: absolute;
  right: 14.27vw;
  bottom: -50px;
  z-index: 1;
  @include respond-to(1921, "min-width") {
    @include respond-to(1100, "min-height") {
      bottom: 0;
    }
  }
  @include fadeInPrepare(1.5);
  @include respond-to(1440) {
    right: 2.27vw;
  }
  .first-section-img {
    width: 792px;
    @include respond-to(1921, "min-width") {
      @include respond-to(1100, "min-height") {
        width: 41.25vw;
      }
    }
    @include respond-to(1220) {
      width: 56vw;
    }
  }
}
.first-section-img-bg {
  position: absolute;
  bottom: 0;
  &.first-section-img-left {
    right: 48.11vw;
    width: 140px;
    @include respond-to(1440) {
      right: 47.56vw;
    }
  }
  &.first-section-img-right {
    right: 19.18vw;
    width: 141px;
    @include respond-to(1440) {
      right: 9.65vw;
    }
  }
}
.section.active {
  &:not(.first-active) {
    //.section__title,
    //.section__description {
    //  @include fadeInUp()
    //}
    //.first-section-img-area {
    //  @include fadeIn()
    //}
  }
  &.first-active {
    .section__button {
      @include fadeInUp();
    }

    .section-bottom-img {
      height: 44px;
    }

    .section__title {
      &:before {
        width: 20px;
      }
    }

    .section__title,
    .section__description {
      @include fadeInUp();
    }

    .first-section-img-area {
      transition-delay: 0.5s;
      @include fadeIn();
    }
  }
}
</style>
