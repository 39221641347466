import { createWebHistory, createRouter } from 'vue-router'

import Home from '../views/Index.vue';
import contentLayout from '../views/layouts/contentLayout.vue';
import RouterView from '../views/RouterView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/',
    component: contentLayout,
    children: [
      {
        path: 'notice',
        name: 'NoticeRoot',
        component: RouterView,
        children: [
          {
            path: 'list',
            name: '새소식',
            component: () => import('@/views/notice/NoticeList.vue'),
          },
          {
            path: 'detail/:id',
            name: '',
            component: () => import('@/views/notice/NoticeDetail.vue'),
          },
          {
            path: '',
            redirect: '/notice/list',
          },
        ],
      },
      {
        path: 'bob',
        name: 'BobRoot',
        component: RouterView,
        children: [
          {
            path: 'list',
            name: '보리BOB',
            component: () => import('@/views/bob/BobList.vue'),
          },
          {
            path: '',
            redirect: '/bob/list',
          },
        ],
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    window.scrollTo(0, 0)
  }
});


export default router;
