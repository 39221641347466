<template>
  <section class="fourth-section main-section middle-section">
    <div class="middle-section-top">
      <p class="middle-section-text is--active" data-aos="fade-up">
        의료비 <b>지원항목</b>
      </p>
    </div>
    <div class="fourth-section-list-area">
      <swiper
        :slidesPerView="4"
        :centeredSlides="true"
        :space-between="40"
        :breakpoints="{
          '640': {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          '768': {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          '1024': {
            slidesPerView: 3,
          },
          '1200': {
            slidesPerView: 3,
          },
          '1440': {
            slidesPerView: 4,
          },
        }"
      >
        <swiper-slide>
          <div class="middle-section-count slide active">
            <div>
              <strong class="count__number">12</strong>
              <span class="count__unit">과목</span>
            </div>
            <p class="count__period">최대 70항목</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="flip-card slide">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img
                  src="@/assets/images/icons/dental.png"
                  alt=""
                  class="flip-card__icon ophthalmology"
                />
                <strong class="flip-card__text">치과</strong>
              </div>
              <div class="flip-card-back">
                <h2 class="flip-card__title">치과</h2>
                <p class="flip-card__description">
                  충치치료<br />
                  크라운(지르코니아)<br />
                  임플란트<br />
                  임플란트틀니<br />
                  틀니<br />
                  교정(부정교합)
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="flip-card slide">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img
                  src="@/assets/images/icons/orthopedic.png"
                  alt=""
                  class="flip-card__icon ophthalmology"
                />
                <strong class="flip-card__text">정형/신경외과</strong>
              </div>
              <div class="flip-card-back">
                <h2 class="flip-card__title">정형/신경외과</h2>
                <p class="flip-card__description">
                  무릎인공관절수술<br />
                  고관절인공관절수술<br />
                  어깨회전근개파열수술<br />
                  어깨인공관절수술<br />
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="flip-card slide">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img
                  src="@/assets/images/icons/medicine.png"
                  alt=""
                  class="flip-card__icon ophthalmology"
                />
                <strong class="flip-card__text">내과</strong>
              </div>
              <div class="flip-card-back">
                <h2 class="flip-card__title">내과</h2>
                <p class="flip-card__description">
                  위내시경 검사<br />
                  대장내시경 검사<br />
                  5대암검진<br />
                  대장용종절제술<br />
                  위용종절제술<br />
                  내시경이물제거술
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="flip-card slide">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <img
                  src="@/assets/images/icons/otorhinolaryngology.png"
                  alt=""
                  class="flip-card__icon ophthalmology"
                />
                <strong class="flip-card__text">이비인후과</strong>
              </div>
              <div class="flip-card-back">
                <h2 class="flip-card__title">이비인후과</h2>
                <div class="flip-card-description-area">
                  <p class="flip-card__description">
                    청력검사<br />
                    청각장애진단<br />
                    보청기<br />
                    갑상선검사<br />
                    어지럼증/이명검사<br />
                    기면증/수면다원검사
                  </p>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
export default {
  name: "FourthSection",
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style scoped lang="scss">
.fourth-section {
  background: $point2;
  color: $white;
  overflow: hidden;
  ::v-deep .fp-controlArrow {
    display: none;
  }
  .fourth-section-list-area {
    margin-top: 18vh;
    display: flex;
    align-items: center;
    //transform: translateX(368px);

    .middle-section-count {
      //width: 100vw !important;
      margin-top: 50px;
      flex: none;
    }
  }
}
.flip-card {
  background-color: transparent;
  width: 328px !important;
  height: 328px;
  border-radius: 16px;
  + .flip-card {
    margin-left: 40px;
  }
  &:hover,
  &.active,
  &:focus {
    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: $white;
  color: $black;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;

  .flip-card {
    &__icon {
      margin-bottom: 30px;
      &.ophthalmology {
        width: 84px;
      }
    }
    &__text {
      display: block;
      @include setText(30, 400, 38);
    }
  }
}

.flip-card-back {
  background-color: $point;
  color: $black;
  transform: rotateY(180deg);
  border-radius: 16px;
  padding: 40px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .flip-card-description-area {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .flip-card__ {
    &title {
      @include setText(30, 700, 38);
    }
    &description {
      margin-top: 50px;
      font-size: 14px;
      line-height: 160%;
    }
  }
}

@include tabletMiddle();
</style>
