<template>
  <section class="second-section main-section middle-section">
    <div class="middle-section-top">
      <p class="middle-section-text second-section-title is--active">
        보리<br />
        <b>의료비 지원액</b>
      </p>
    </div>
    <div class="middle-section-count">
      <div>
        <span class="count__number target" id="numberCount"></span>
        <span class="count__unit">억원</span>
      </div>
      <p class="count__period">2019년 부터 2025년 까지 기준</p>
    </div>
  </section>
</template>

<script>
import { CountUp } from "countup.js/dist/countUp.js";
import { defineComponent, watch } from "vue";

export default defineComponent({
  name: "SecondSection",
  components: {},
  props: {
    isSecondCountingStart: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const easingFn = function (t, b, c, d) {
      const ts = (t /= d) * t;
      const tc = ts * t;
      return b + c * (tc + -3 * ts + 3 * t);
    };
    const options = {
      startVal: 0,
      duration: 1,
      easingFn,
    };

    watch(
      () => props.isSecondCountingStart,
      () => {
        const countUp = new CountUp("numberCount", 15, options);
        countUp.start();
      }
    );

    return {};
  },
});
</script>

<style scoped lang="scss">
.second-section {
  background-color: $point;
  color: $white;
  background-image: url("../../../assets/images/main/second-bg.png");
  background-size: 334px;
  background-position: 50% 90%;
  background-repeat: no-repeat;
  @include respond-to() {
    background-size: 257px;
  }
  .count__period {
    @include fadeInUpPrepare(0.8);
  }
}
@include pcMiddle();
.section.active {
  .count__period {
    @include fadeInUp();
  }
}
</style>
