<template>
  <section class="fourth-section main-section middle-section">
    <div class="middle-section-top">
      <p class="middle-section-text is--active text-effect-text-wrap">
        <span data-aos="text-effect">의료비</span
        ><b data-aos="text-effect" data-aos-delay="200">지원항목</b>
      </p>
      <p class="count__period text-effect-text-wrap">
        <span data-aos="text-effect" data-aos-delay="400"
          >12개 과목, 최대 70항목</span
        >
      </p>
    </div>
    <div
      class="fourth-section-list-area"
      data-aos="fade-left"
      data-aos-delay="600"
    >
      <swiper :width="264" :space-between="24">
        <swiper-slide>
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-back dental">
                <h2 class="flip-card__title">치과</h2>
                <p class="flip-card__description">
                  충치치료<br />
                  크라운(지르코니아)<br />
                  임플란트<br />
                  임플란트틀니<br />
                  틀니<br />
                  교정(부정교합)
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-back orthopedic">
                <h2 class="flip-card__title">정형/신경외과</h2>
                <p class="flip-card__description">
                  무릎인공관절수술<br />
                  고관절인공관절수술<br />
                  어깨회전근개파열수술<br />
                  어깨인공관절수술<br />
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-back medicine">
                <h2 class="flip-card__title">내과</h2>
                <p class="flip-card__description">
                  위내시경 검사<br />
                  대장내시경 검사<br />
                  5대암검진<br />
                  대장용종절제술<br />
                  위용종절제술<br />
                  내시경이물제거술
                </p>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="flip-card">
            <div class="flip-card-inner">
              <div class="flip-card-back otorhinolaryngology">
                <h2 class="flip-card__title">이비인후과</h2>
                <div class="flip-card-description-area">
                  <p class="flip-card__description">
                    청력검사<br />
                    청각장애진단<br />
                    보청기<br />
                    갑상선검사<br />
                    어지럼증/이명검사<br />
                    기면증/수면다원검사
                  </p>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
export default {
  name: "FourthSectionMobile",
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style scoped lang="scss">
.fourth-section {
  background: $point2;
  color: $white;
  overflow: hidden;
  padding-bottom: 31vw;

  .fourth-section-list-area {
    padding-left: 13.33vw;
    margin-top: 8vh;
  }
}
.flip-card {
  background-color: transparent;
  width: 264px;
  height: 360px;
  border-radius: 16px;
  + .flip-card {
    margin-left: 40px;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: $white;
  color: $black;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;

  .flip-card {
    &__icon {
      margin-bottom: 30px;
      &.ophthalmology {
        width: 84px;
      }
    }
    &__text {
      display: block;
      @include setText(30, 400, 38);
    }
  }
}

.flip-card-back {
  background-color: $white;
  color: $black;
  border-radius: 16px;
  padding: 32px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 72px;
  background-position: 85% 90%;
  &.ophthalmology {
    background-image: url("../../../assets/images/icons/ophthalmology.png");
    background-size: 57px;
  }
  &.dental {
    background-image: url("../../../assets/images/icons/dental.png");
  }
  &.orthopedic {
    background-image: url("../../../assets/images/icons/orthopedic.png");
  }
  &.medicine {
    background-image: url("../../../assets/images/icons/medicine.png");
  }
  &.maternity {
    background-image: url("../../../assets/images/icons/maternity.png");
  }
  &.otorhinolaryngology {
    background-image: url("../../../assets/images/icons/otorhinolaryngology.png");
    background-size: 43px;
  }
  &.psychiatry {
    background-image: url("../../../assets/images/icons/psychiatry.png");
  }
  &.plastic-surgery {
    background-image: url("../../../assets/images/icons/plastic-surgery.png");
  }
  &.pediatrics {
    background-image: url("../../../assets/images/icons/pediatrics.png");
  }
  &.burn {
    background-image: url("../../../assets/images/icons/burn.png");
  }
  &.rehabilitation {
    background-image: url("../../../assets/images/icons/rehabilitation.png");
  }
  &.hanbang {
    background-image: url("../../../assets/images/icons/hanbang.png");
  }
  &.animal {
    background-image: url("../../../assets/images/icons/animal.png");
  }

  .flip-card-description-area {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .flip-card__ {
    &title {
      @include setText(20, 700, 25);
    }
    &description {
      margin-top: 16px;
      font-size: 14px;
      line-height: 160%;
    }
  }
}
@include mobileMiddle();
</style>
