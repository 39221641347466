<template>
  <section class="third-section main-section middle-section">
    <div class="middle-section-top">
      <p class="middle-section-text is--active">
        보리를 통해<br /><b>의료비 지원 신청되신 분</b>
      </p>
    </div>
    <div class="middle-section-count">
      <div>
        <span class="count__number target" id="numberCount2"></span>
        <span class="count__unit">명</span>
      </div>
      <p class="count__period">2019년 1월부터 2025년 까지 기준</p>
    </div>
  </section>
</template>

<script>
import { CountUp } from "countup.js/dist/countUp.js";
import { defineComponent, watch } from "vue";

export default defineComponent({
  name: "ThirdSection",
  components: {},
  props: {
    isThirdCountingStart: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const easingFn = function (t, b, c, d) {
      const ts = (t /= d) * t;
      const tc = ts * t;
      return b + c * (tc + -3 * ts + 3 * t);
    };

    const options = {
      startVal: 1000,
      duration: 2.5,
      easingFn,
      useGrouping: true,
      decimalPlaces: 0,
    };

    watch(
      () => props.isThirdCountingStart,
      () => {
        const countUp = new CountUp("numberCount2", 4500, options);
        countUp.start();
      }
    );

    return {};
  },
});
</script>

<style scoped lang="scss">
.third-section {
  color: $white;
  background-image: url("../../../assets/images/main/third-bg-img.jpeg");
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  @include respond-to() {
    background-image: url("../../../assets/images/main/third-bg-img-mobile.jpeg");
  }
  .count__period {
    @include fadeInUpPrepare(0.8);
  }
}
@include pcMiddle();
.section.active {
  .count__period {
    @include fadeInUp();
  }
}
</style>
