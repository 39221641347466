<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue'


export default defineComponent({
  name: 'App'
})
</script>

<style lang="scss">
@import "~@/assets/style/base/_fonts.scss";
@import "~@/assets/style/base/_reset.scss";
@import "~@/assets/style/layout/_common.scss";
</style>
